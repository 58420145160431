
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IOfferOption } from '@alao-frontend/core'
import BenefitTooltip from '~/components/BenefitTooltip.vue'
import IconInfo from '~/assets/icons/icon-info.svg?inline'

@Component({
  components: {
    BenefitTooltip,
    IconInfo,
  },
})
export default class OfferOptionsItemBase extends Vue {
  @Prop({ required: true, default: '#000000', type: String })
  private readonly bgColor!: string

  @Prop({
    required: false,
    default: false,
    type: Boolean,
  })
  readonly highlightBold!: boolean

  @Prop({ type: Object })
  readonly item?: IOfferOption

  get styles () {
    return {
      backgroundColor: this.bgColor,
    }
  }
}
