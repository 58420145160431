
import { Component, Prop, Ref, Vue, Watch } from 'nuxt-property-decorator'
import throttle from 'lodash/throttle'

export enum TooltipPositions {
  TOP = 'top',
  TOP_START = 'top-start',
  TOP_END = 'top-end',
  BOTTOM = 'bottom',
  BOTTOM_START = 'bottom-start',
  BOTTOM_END = 'bottom-end',
  LEFT = 'left',
  LEFT_START = 'left-start',
  LEFT_END = 'left-end',
  RIGHT = 'right',
  RIGHT_START = 'right-start',
  RIGHT_END = 'right-end',
}

@Component
export default class AppTooltip extends Vue {
  // @TODO: Add prop: trigger ('hover', 'click', etc.)
  @Prop({
    default: TooltipPositions.BOTTOM,
    type: String,
    validator (value: string): boolean {
      return Object.values(TooltipPositions).includes(value as TooltipPositions)
    },
  })
  private readonly position!: TooltipPositions

  @Prop({
    default: true,
    type: Boolean,
  })
  private readonly active!: boolean

  @Ref('trigger') readonly triggerElement!: HTMLDivElement
  @Ref('tooltip') readonly tooltipElement!: HTMLDivElement

  private isContentShowed = false
  private throttledEventsHandler = throttle(this.handleScrollResize, 700)

  getPositionCoords (): { y: number, x: number } {
    // @TODO: Calculate positioning considering viewport

    const trigger = this.triggerElement.getBoundingClientRect()
    const tooltip = this.tooltipElement.getBoundingClientRect()

    // @TODO: Add calculations for other positions (top, left, right)
    const { BOTTOM_START, BOTTOM, BOTTOM_END } = TooltipPositions

    let coords = { x: 0, y: 0 }

    switch (this.position) {
      case BOTTOM_START: {
        coords = {
          x: trigger.left,
          y: trigger.top + trigger.height,
        }
        break
      }
      case BOTTOM: {
        coords = {
          x: trigger.left + trigger.width / 2 - tooltip.width / 2,
          y: trigger.top + trigger.height,
        }
        break
      }
      case BOTTOM_END: {
        coords = {
          x: trigger.left + trigger.width - tooltip.width,
          y: trigger.top + trigger.height,
        }
        break
      }
    }

    if (coords.x < 0) {
      coords.x = 15
    }

    if (coords.x + tooltip.width > window.innerWidth) {
      coords.x = window.innerWidth - tooltip.width - 15
    }

    return coords
  }

  async onMouseOver () {
    if (this.isContentShowed) {
      return
    }
    this.isContentShowed = true
    await this.$nextTick()

    const { y, x } = this.getPositionCoords()
    this.tooltipElement.style.top = `${y}px`
    this.tooltipElement.style.left = `${x}px`
  }

  onMouseLeave () {
    this.isContentShowed = false
  }

  handleScrollResize () {
    this.isContentShowed = false
  }

  addEventListeners (): void {
    window.addEventListener('scroll', this.throttledEventsHandler, { passive: true })
    window.addEventListener('resize', this.throttledEventsHandler, { passive: true })
  }

  removeEventListeners (): void {
    window.removeEventListener('scroll', this.throttledEventsHandler)
    window.removeEventListener('resize', this.throttledEventsHandler)
  }

  beforeDestroy () {
    this.removeEventListeners()
  }

  @Watch('isContentShowed')
  watchIsActive (v: boolean) {
    v ? this.addEventListeners() : this.removeEventListeners()
  }
}
